<template>
	<div class="loader">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="loader__img" viewBox="0 0 100 100">
			<circle cx="30" cy="50" fill="#5caae7" r="20">
				<animate attributeName="cx" repeatCount="indefinite" dur="1.5873015873015872s" keyTimes="0;0.5;1" values="30;70;30" begin="-0.7936507936507936s"></animate>
			</circle>
			<circle cx="70" cy="50" fill="#3990d4" r="20">
				<animate attributeName="cx" repeatCount="indefinite" dur="1.5873015873015872s" keyTimes="0;0.5;1" values="30;70;30" begin="0s"></animate>
			</circle>
			<circle cx="30" cy="50" fill="#5caae7" r="20">
				<animate attributeName="cx" repeatCount="indefinite" dur="1.5873015873015872s" keyTimes="0;0.5;1" values="30;70;30" begin="-0.7936507936507936s"></animate>
				<animate attributeName="fill-opacity" values="0;0;1;1" calcMode="discrete" keyTimes="0;0.499;0.5;1" dur="1.5873015873015872s" repeatCount="indefinite"></animate>
			</circle>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'LoaderComponent',
}
</script>

<style lang="scss" scoped>
	.loader {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba($color: #ffffff, $alpha: .5);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.loader__img {
		max-width: 200px;
	}
</style>